<script setup lang="ts">

</script>

<template>
  <div>

    <UMain>
      <slot />
    </UMain>



  </div>
</template>